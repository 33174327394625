.myfathersstory {
 padding: 5%;  
   
}

.myfathersstory h2 {
    padding-left: 12%; 
    padding-right: 12%; 
}

@media only screen and (min-width: 1020px) {
.myfathersstory h2 {
    padding-left: 15%; 
    padding-right: 15%; 
}
}