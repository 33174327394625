.footer p {
    margin: 0;
    padding: 10px;  
}

.footer {
    border-top: 1px solid black; 

}

.footer .share {
    border-bottom: 1px solid black;  
    text-align: center;    
}

.footer .facebook {
    color: rgb(24 119 242); 
    margin: 20px;  
}

.footer .twitter {
    color: rgb(29, 155, 240); 
    margin: 20px; 
}

.footer .developer {
    font-size: 14px; 
}

.footer a {

    margin-top: 5px; 
    margin-left: 10px; 
    margin-right: 20px; 
    margin-bottom: 10px; 
    text-align: center;  
}

@media only screen and (min-width: 600px) {
    .footer {
        display:flex; 
        justify-content: space-between; 
    }
    

}