html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

img {
    width: 100%; 
    height: auto;
    padding-top: 5px; 

}

div .blue {
    background-color: rgb(198, 198, 253); 
}

div .red {
    background-color: rgb(252, 233, 197);
}

.top {
    padding-top: 20px;  
    margin-top: 20px;  
}

.top h2 {
    margin-top: 0; 
}

a {
    display: inline-block; 
}

p, h3, h4, .italics, .p-link {
    padding: 0 5% 0; 
 
}

.NavLink {
    text-align: center; 
    font-size: 15px; 
    margin-left: auto; 
    margin-right: auto; 
    display: flex; 
    justify-content: center; 
}

h2 {
    text-align: center; 
}

.learn-more {
width: 90%; 
text-align: center;
margin-left: 5%; 
margin-right: 5%;
 
}

figcaption {
    text-align: center;
    padding-top: 10px; 

}

figure p {
    font-size: 12px;
    text-align: center;
}

figure {
    padding-bottom: 10px; 
}

.next-button {
    font-size: 25px; 
    width: 134px; 
    position: relative; 
    left: -99px; 
    margin-left: 50%; 
    margin-right: 50%; 
    background-color: #e7e7e7;

  border: black 1px solid; 
  color: black; 
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  border-radius: 8px; 
  transition-duration: 1s;

}

.next-button:hover {

        background-color: rgb(246, 246, 246); 

      
}

.references {
    margin-top: 15px; 
    padding-bottom: 15px; 
}

.references h2 {
    padding-top: 15px; 
}

@media only screen and (min-width: 650px) {
    html {
        font-size: 16px; 
    }
    .NavLink {
        font-size: 20px;
    }

}

@media only screen and (min-width: 380px) {
    .portrait {
    width: 80%; 
    margin-left: 10%; 
    margin-right: 10%; 
}
}

@media only screen and (min-width: 460px) {
img, .portrait {
        width: 66%; 
        margin-left: 17%; 
        margin-right: 17%; 
    }

}

@media only screen and (min-width: 530px) {
     .portrait {
            width: 50%; 
            margin-left: 25%; 
            margin-right: 25%; 
        }
    
    }

    @media only screen and (min-width: 770px) {
        .portrait, img {
               width: 34%; 
               margin-left: 33%; 
               margin-right: 33%; 
           }
       
       }
       
    @media only screen and (min-width: 1180px) {
        .portrait {
               width: 26%; 
               margin-left: 37%; 
               margin-right: 37%; 
           }
       
       }

       @media only screen and (min-width: 920px) {
         .info p, .info h3, .info h4 {
            margin-left: 12%; 
            margin-right: 12%; 
         }
       }