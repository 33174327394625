.header {
    text-align: center; 
    width: 100%; 
    height: 350px;
    display: flex;  
}

.header .blue-cross {
    width: 20%; 
    height: 100%;
    background-color: rgb(0,32,91); 
}

.header-text {
    width: 80%; 
    height: 100%; 
}

.header .emil {
    background-color: rgb(0,32,91); 
    margin-top: 0; 

    height: 38%; 
    display: flex; 
    flex-wrap: wrap;      
}

.header h1 {
    margin: 0; 
    font-size: 25px; 
    padding: 10px 0 5px;
    color: rgb(243, 243, 243); 
    width: 100%;   
}

.header .emil p {
margin: 0%; 
color: rgb(243, 243, 243); 
font-size: 10px; 
padding-bottom: 5px; 
width: 100%;   
}

.header .white-cross {
    height: 4%; 
}

.header .nav {
    background-color: rgb(186,12,47); 
    margin-left: 6%;
    height: 58%; 
    display: flex;
    flex-wrap: wrap;  
    align-content: space-around; 
}

.header .nav div {
    width: 100%; 
}

.header .nav .NavLink {
    display: inline-block; 
    color: rgb(243, 243, 243); 
    font-size: 15px; 
    text-decoration: none; 
}

.header .nav .NavLink:hover {
text-decoration: underline; 
}


@media only screen and (min-width: 400px) {
    .header .nav  {
        margin-left: 4%; 
    }

    }

@media only screen and (min-width: 550px) {
        .header h1 {
            font-size: 35px; 
        }
    
        }

@media only screen and (min-width: 850px) {
.header h1 {
    font-size: 40px; 
}
}

@media only screen and (min-width: 920px) {
    .header .blue-cross {
        width: 10%;
         
 
    }
    .header-text {
        width: 90%; 

    }
    .header .nav {

        margin-left: 2%;

    
    }

    .header {
        height: 350px; 
    }
    .header .emil {
        height: 37%
        }
    .header .emil p {
        font-size: 14px; 
    }
        

        
        .header .nav {

            height: 59%; 
        
        }

}
