.comments h2 {
    padding-top: 20px; 
    margin-top: 10px; 
}

.comments p {
    text-align: center; 
}

.share { 
    border-top: 1px solid black;
    text-align: center; 
    padding-bottom: 10px;    
}

.share .facebook {
    color: rgb(24 119 242); 
    margin: 20px;  
}

.share input {

    margin-bottom: 20px; 
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 5px; 
}

.share textarea{
    height: 100px;
    width: 250px; 
    display: block;
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 10px; 
    margin-bottom: 30px;  
}

.share input[type='submit']{
    font-size: 20px; 
    margin-top: 20px; 
    margin-bottom: 20px;
    margin-left: 0; 
    margin-right: 0;  
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
}

.share .comment {
    width: 90%;
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 20px; 
    padding-bottom: 10px; 
    border-radius: 10px; 
}

.share .comment p {
    font-size: 16px;

    margin-top: 0;  
    padding: 20px 20px 0;
}

.share .comment b {
    font-size: 10px; 

}

.disquscomments {
    width: 90%;
    margin-left: 5%; 
    margin-right: 5%; 
}

@media only screen and (min-width: 410px) {

.share .comment {
    width: 70%; 
}

.share .flex {
    display: flex; 
    width: 100%; 
}

.left {
    margin-left: auto; 

}

.right {
    margin-right: auto; 

}
}

@media only screen and (min-width: 492px) {


.share textarea {
    width: 350px; 
}
}

@media only screen and (min-width: 610px) {
    .share .comment {
        width: 60%; 
    }
}




@media only screen and (min-width: 900px) {
    .share .comment {
        width: 40%; 
    }
}